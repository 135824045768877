import React from 'react';
import {observer} from 'mobx-react';

import analytics from 'analytics.js';
import autobind from 'common/decorators/autobind.js';
import {HICAPS, TELEHEALTH} from 'core/constants';
import Chips from 'core/components/Chips.js';
import {
    BP_FHIR,
    PRACTICE_LOC_CONTACT_TYPE,
    NEARBY_HOSPITAL_CONTACT_TYPE,
    SHOW_REFERRAL_BTN,
} from 'core/constants.js';
import CollapsibleNumber from 'core/components/CollapsibleNumber.js';
import Icon from 'core/components/Icon.js';
import LocationInfo from 'core/components/LocationInfo';
import Link from 'core/components/Link.js';
import Tooltip from 'core/components/Tooltip.js';
import {TELEHEALTH_ONLY, TELEHEALTH_HYBRID} from 'core/telehealth.js';
import {classNameHandler} from 'core/utils.js';
import {PracticeStoreContext} from 'core/stores/RootStore.js';
import {
    BPSecureMessageButton,
    BPWriteReferralButton,
    HSReferralButton,
    MDWriteReferralButton,
    ZMWriteReferralButton,
} from 'professional/components/ReferralButtons.js';
import {sessionStorageGetItem} from 'core/utils.js';

@observer
class LocationList extends React.Component {
    static contextType = PracticeStoreContext;

    @autobind
    onClickFaxNumber(practiceLocationId, phoneNumber) {
        const trackData = {
            phoneNumber,
            practiceLocationId,
        };
        analytics.track('practiceLocationFaxClick', trackData);
    }

    renderReferralButtons(location) {
        const {
            getCopyDataForLocation,
            rootStore: {
                paramStore: {client, isClient, isReferrals},
            },
        } = this.context;
        const {bpData, id, mdData, offersTelehealth, zmData} = location;
        const showReferralBtn =
            sessionStorageGetItem(SHOW_REFERRAL_BTN) === 'true';
        if (bpData || mdData || (zmData && showReferralBtn)) {
            return (
                <div className="button-group">
                    {bpData && (
                        <BPWriteReferralButton
                            bpData={bpData}
                            eventData={{
                                'practice_location_id': id,
                            }}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {mdData && (
                        <MDWriteReferralButton
                            eventData={{
                                'practice_location_id': id,
                            }}
                            mdData={mdData}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {bpData && client === BP_FHIR && (
                        <BPSecureMessageButton
                            eventData={{
                                'practice_location_id': id,
                            }}
                            fhirData={bpData}
                            telehealthOnly={
                                offersTelehealth === TELEHEALTH_ONLY
                            }
                        />
                    )}
                    {zmData && showReferralBtn && (
                        <ZMWriteReferralButton
                            eventData={{'practice_location_id': id}}
                            telehealthOnly={
                                offersTelehealth == TELEHEALTH_ONLY
                            }
                            zmData={zmData}
                        />
                    )}
                </div>
            );
        } else if (
            (zmData && !showReferralBtn) ||
            (!isClient && isReferrals && location.referralToPractice)
        ) {
            // Bind function for React optimization
            const getCopyData = () => {
                return getCopyDataForLocation(location);
            };
            return (
                <div className="button-group">
                    <HSReferralButton
                        eventData={{
                            eventName: 'copyPracticeLocationDetails',
                            data: {
                                'practice_location': location.id,
                            },
                        }}
                        getCopyData={getCopyData}
                        telehealthOnly={offersTelehealth === TELEHEALTH_ONLY}
                    />
                </div>
            );
        }
        return null;
    }

    getBQData(number, locationId) {
        const {
            practice,
            rootStore: {
                healthFundStore: {healthFund},
            },
        } = this.context;
        return {
            url: '/api/a/v1/events/practice-location-fax-click-event/',
            data: {
                'health_fund': healthFund?.id,
                number,
                'practice_location': locationId,
                'practice_group': practice.id,
            },
        };
    }

    renderInfoList(location, idx, isDepartment = null) {
        const {
            address,
            city,
            fax,
            id,
            phones,
            state,
            offersHicaps,
            offersTelehealth,
        } = location;
        const {locationListDepartmentContactProps} = this.context;
        const shouldRenderPhones = phones?.length >= 1;
        const shouldRenderFax = fax?.number.length;
        const eventLabel = `practice location ${idx + 1}`;
        if (shouldRenderPhones || shouldRenderFax || address) {
            return (
                <dl className="icon-list">
                    {isDepartment &&
                        address &&
                        this.renderHospitalAddress(address)}
                    {(offersTelehealth === TELEHEALTH_HYBRID ||
                        offersTelehealth === TELEHEALTH_ONLY) && (
                        <div>
                            <dt>
                                <Icon name="telehealth" />
                                {`Telehealth: `}
                            </dt>
                            <dd>
                                <address>
                                    {offersTelehealth === TELEHEALTH_HYBRID
                                        ? `Offers Telehealth`
                                        : `${TELEHEALTH} only`}
                                </address>
                            </dd>
                        </div>
                    )}
                    {shouldRenderPhones &&
                        phones.map((phone) => {
                            if (!phone) {
                                return null;
                            }
                            const {description, number} = phone;
                            return (
                                <div key={number}>
                                    <dt>
                                        <Icon name="phone" />
                                        {'Phone:'}
                                    </dt>
                                    <dd aria-live="polite">
                                        <CollapsibleNumber
                                            number={number}
                                            onClick={() => {
                                                if (isDepartment) {
                                                    this.context.showModal(
                                                        'contact',
                                                        locationListDepartmentContactProps(
                                                            location,
                                                            number,
                                                        ),
                                                    );
                                                } else {
                                                    this.context.showModal(
                                                        'contact',
                                                        {
                                                            selectedItem: {
                                                                id:
                                                                    location.id,
                                                                type: PRACTICE_LOC_CONTACT_TYPE,
                                                            },
                                                            phoneNumber: number,
                                                        },
                                                    );
                                                }
                                            }}
                                            title={`Click to show phone number for ${city} ${state}`}
                                            type="phone"
                                        />
                                        {description && <p>{description}</p>}
                                    </dd>
                                </div>
                            );
                        })}
                    {shouldRenderFax && (
                        <div>
                            <dt>
                                <Icon name="print" />
                                {'Fax:'}
                            </dt>
                            <dd aria-live="polite">
                                <CollapsibleNumber
                                    number={fax.number}
                                    onClick={() =>
                                        this.onClickFaxNumber(id, fax.number)
                                    }
                                    title={`Click to show fax number for ${city} ${state}`}
                                    type="fax"
                                />
                                {fax.description && <p>{fax.description}</p>}
                            </dd>
                        </div>
                    )}
                    {offersHicaps && (
                        <div>
                            <dt>
                                <Icon name="card" />
                                {`Hicaps: `}
                            </dt>
                            <dd>
                                <address>Offers {HICAPS}</address>
                            </dd>
                        </div>
                    )}
                </dl>
            );
        }
        return null;
    }

    renderPracticeLocation() {
        const {practice, tabs, telehealthLocations} = this.context;
        const pLength = practice.locations?.length;
        if (!pLength && !telehealthLocations?.length) {
            return null;
        }
        const title = `${pLength} Practice Location${pLength > 1 ? 's' : ''}`;
        const hasLocationsTab = tabs.find((tab) => tab.hash === 'locations');

        return (
            <div className="location-list">
                {!hasLocationsTab && <h2>{title}</h2>}
                <ul className="practices">
                    {practice.locations.map((location, idx) => (
                        <li className="location" key={`prac-${location.id}`}>
                            <LocationInfo
                                address={location.address}
                                practiceName={`${location.city} ${location.state}`}
                                practiceUrl={location.practiceUrl}
                                staticUrl={location.staticUrl}
                                streetAddress={location.streetAddress}
                                telehealthOnly={
                                    location.offersTelehealth ===
                                    TELEHEALTH_ONLY
                                }
                            />
                            {this.renderInfoList(location, idx)}
                            {this.renderReferralButtons(location)}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    renderHospitalAddress(address) {
        const googlePreviewUrl = `https://www.google.com/maps/preview/?q=${address}`;
        return (
            <div>
                <dt>
                    <Icon name="mapPin" />
                    {'Address: '}
                </dt>
                <dd>
                    <address>
                        <Link
                            href={googlePreviewUrl}
                            isExternalLink={true}
                            title="View on Google Map"
                        >
                            {address}
                        </Link>
                    </address>
                </dd>
            </div>
        );
    }

    renderHospitalPhoneNumber(location) {
        const {phone, id, locality, street1, name} = location;
        if (phone) {
            return (
                <div>
                    <dt>
                        <Icon name="phone" />
                        {'Phone:'}
                    </dt>
                    <dd>
                        <CollapsibleNumber
                            customClass="expanded"
                            number={phone}
                            onClick={() => {
                                this.context.showModal('hospitalContact', {
                                    contactId: id,
                                    contactName: name,
                                    items: [
                                        {
                                            id,
                                            locality: {
                                                ...locality,
                                                post_code: locality.postCode,
                                            },
                                            street1,
                                            type: NEARBY_HOSPITAL_CONTACT_TYPE,
                                            name,
                                        },
                                    ],
                                    phoneNumber: phone,
                                });
                                this.context.rootStore.analyticsStore.genericTrackEvent(
                                    '/api/hospitals/v1/events/phone-clicks/',
                                    {
                                        hospital: id,
                                        phone,
                                    },
                                );
                            }}
                            title={`Click to show phone number for ${phone}`}
                        />
                    </dd>
                </div>
            );
        }
    }

    renderHospitalFaxNumber(location) {
        const {fax, id} = location;
        if (fax) {
            return (
                <div>
                    <dt>
                        <Icon name="print" />
                        {'Fax:'}
                    </dt>
                    <dd>
                        <CollapsibleNumber
                            customClass="expanded"
                            number={fax}
                            title={`Click to show fax number for ${fax}`}
                            type={'fax'}
                        />
                    </dd>
                </div>
            );
        }
    }

    renderHFRPill(hfr) {
        if (hfr) {
            const {displayName, name, tooltip} = hfr;
            const className = classNameHandler(name);
            return (
                <Chips
                    labels={[{name: displayName, tooltip}]}
                    customClass={className}
                />
            );
        }
    }

    getClosestInNetworkList(healthFund, locations, tooltipText) {
        const {id: sourceId} = this.context.practice;
        if (healthFund?.id && locations?.length > 0) {
            return (
                <div className="location-list">
                    <header className="closest-hospitals">
                        <h2>
                            Closest in network hospitals
                            <Tooltip
                                content={tooltipText}
                                defaultPosition="top"
                            >
                                <Icon name="info" />
                            </Tooltip>
                        </h2>
                    </header>
                    <ul className="practices">
                        {locations?.map((location) => {
                            const {
                                id,
                                name,
                                url,
                                group,
                                locality,
                                street1,
                                healthFundRelationship,
                            } = location;
                            const address = `${street1}, ${locality?.name}, ${locality?.state?.name}, ${locality?.postCode}`;
                            return (
                                <li className="location" key={`hosp-${id}`}>
                                    <LocationInfo
                                        address={address}
                                        practiceName={name}
                                        practiceUrl={url}
                                        hospitalGroup={group}
                                        isHospitalPage={true}
                                    />
                                    <dl className="icon-list">
                                        {this.renderHospitalAddress(address)}
                                        {this.renderHospitalPhoneNumber(
                                            location,
                                        )}
                                        {this.renderHospitalFaxNumber(
                                            location,
                                        )}
                                    </dl>
                                    {this.renderHFRPill(
                                        healthFundRelationship,
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
        return null;
    }

    getDepartments(departments) {
        if (departments?.length > 0) {
            return (
                <div className="location-list">
                    <header className="closest-hospitals">
                        <h2>Departments</h2>
                    </header>
                    <ul className="practices">
                        {departments.map((location, idx) => (
                            <li
                                className="location"
                                key={`prac-${location.id}`}
                            >
                                <LocationInfo
                                    address={location.address}
                                    isHospitalPage={true}
                                    practiceName={location.name}
                                    practiceUrl={location.url}
                                    telehealthOnly={
                                        location.offersTelehealth ===
                                        TELEHEALTH_ONLY
                                    }
                                />
                                {this.renderInfoList(location, idx, true)}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    }

    renderHospitalLocation() {
        const {activeTab} = this.context;
        const {departments, nearbyHospitals} = this.context.practice;
        const healthFund = this.context.rootStore.healthFundStore.healthFund;
        let tooltipText = '';
        if (healthFund) {
            tooltipText = `${healthFund.name} members get greater value at hospitals in our network.`;
        }

        return (
            <>
                {activeTab?.hash !== 'departments' &&
                    this.getClosestInNetworkList(
                        healthFund,
                        nearbyHospitals,
                        tooltipText,
                    )}
                {activeTab?.hash !== 'locations' &&
                    this.getDepartments(departments)}
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderPracticeLocation()}
                {this.renderHospitalLocation()}
            </>
        );
    }
}

export default LocationList;
